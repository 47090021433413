@use "sass:meta" as ---886wn800nib;
#nprogress {
  pointer-events: none;
}

#nprogress .bar {
  background-color: $c-purple;
  height: 3px;
  transition-duration: $duration_long;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  background-color: $c-red;

  @include knowely-styles {
    background-color: $c-purple;
  }
}

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0;
  width: 100px;
  height: 100%;
  opacity: 1.0;
  transform: rotate(3deg) translate(0px, -4px);
  box-shadow: 0 0 10px #{$c-red}, 0 0 5px #{$c-red};

  @include knowely-styles {
    box-shadow: 0 0 10px #{$c-purple}, 0 0 5px #{$c-purple};
  }
}

;@include ---886wn800nib.load-css("sass-embedded-legacy-load-done:75304");