@use "sass:meta" as ---886wn800nib;/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

* {
  box-sizing: border-box;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  padding: 0;
  margin: 0;
  font: inherit;
  vertical-align: baseline;
  border: 0;
}

/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote {

  &:before,
  &:after {
    content: "";
  }
}

q {

  &:before,
  &:after {
    content: "";
  }
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

/*
why: iOS 15 coloring all `select` and `button` to `-apple-system-blue;`
- https://developer.apple.com/forums/thread/690529
- https://stackoverflow.com/questions/74853859/how-to-avoid-safari-adding-apple-system-blue-to-my-button
*/
button,
select {
  color: #0F0F10;
}

;@include ---886wn800nib.load-css("sass-embedded-legacy-load-done:75262");