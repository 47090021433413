@use "sass:meta" as ---886wn800nib;%input-reset {
  border: none;
  outline: none;
  padding: 0;
  border-radius: 0;
  background-color: transparent;
  color: currentColor;
  font: inherit;
  width: 100%;
  appearance: none;
}

%input {
  @include text-platform-main;

  border-radius: rem-calc(10);
  border: 1px solid transparent;
  height: rem-calc(48);
  color: getColor($c-text-secondary);
  background-color: getColor($c-fill-secondary);

  transition-timing-function: $easing;
  transition-duration: $duration;
  transition-property: color, border-color, opacity, background-color;

  display: block;
  width: 100%;
  outline: none;
}

%input-hover {
  border-color: getColor($c-border-hovered);
}

%input-focus {
  outline: none;
  border-color: getColor($c-border-pressed);
}

%input-invalid {
  border-color: getColor($c-border-danger-bold);
  background-color: getColor($c-bg-danger);

  &:focus,
  &:hover {
    border-color: getColor($c-border-danger-bold);
  }
}

%input-valid {
  border-color: getColor($c-border-success-bold);
  background-color: getColor($c-bg-success);

  &:focus,
  &:hover {
    border-color: getColor($c-border-success-bold);
  }
}

%input-disabled {
  cursor: not-allowed;
  border-color: transparent;
  pointer-events: auto;
  opacity: 0.5;
}

%input-padding {
  padding: rem-calc(16 12);
}

;@include ---886wn800nib.load-css("sass-embedded-legacy-load-done:82996");